<template>
  <div class="view-home">
      <div class="forums-block">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><a href="/new-main">Главная</a></li>
              <li class="breadcrumbs-item"> Мероприятия</li>
            </ul>
          </nav>
          <div class="events-title">
            Форумы
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
        </div>
        <div class="forums-section">
          <div class="container">
            <div class="forum-slide-item">
              <div class="forum-slide-title">
                Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и одаренности детей и молодежи»
              </div>
              <div class="forum-slide-text">
                Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области образования, развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей также будут проведены в рамках форума.
              </div>
              <div class="forum-slide-details">
                <div>
                  <span>Даты проведения:</span> 05.07.2024 - 07.07.2024 гг
                </div>
                <div>
                  <span>Место проведения:</span> г. Астана, пр. Мангилик Ел, 55/20
                </div>
              </div>
              <router-link to="#" class="forum-slide-link">
                Узнать подробнее
              </router-link>
            </div>
          </div>
        </div>
      </div>
    <div class="forums-filters-block forums-img-back">
      <div class="container">
        <div class="forums-filters">
          <div class="filter-date date-icon">
            <el-select v-model="value1" placeholder="Дата проведения" class="filter-date-select">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="filter-period">
            <el-select v-model="value2" placeholder="Предстоит" class="filter-date-select">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="filter-place">
            <el-select v-model="value3" placeholder="Место проведения" class="filter-date-select">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="forum-list-block">
      <div class="container">
          <div class="forum-list">
            <div class="forum-list-item">
              <div class="forum-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img-2.png"
                    fit="fit"></el-image>
              </div>
              <div class="forum-list-item-info">
                <div class="forum-list-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forum-list-item-title">
                  Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и
                  одаренности детей и молодежи»
                </div>
                <div class="forum-list-item-text">
                  Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области
                  образования,
                  развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей
                  также будут проведены в рамках форума.е вызовы, системы психолого-педагогических инструментов,
                  связанные
                  с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет
                  возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forum-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <router-link to="#" class="forum-list-item-link">
                  Узнать подробнее
                </router-link>
              </div>
            </div>
            <div class="forum-list-item">
              <div class="forum-list-item-img">
                <el-image
                    style="width: 100%; height: 100%"
                    src="/images/forums-slide-img-2.png"
                    fit="fit"></el-image>
              </div>
              <div class="forum-list-item-info">
                <div class="forum-list-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forum-list-item-title">
                  Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и
                  одаренности детей и молодежи»
                </div>
                <div class="forum-list-item-text">
                  Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области
                  образования,
                  развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей
                  также будут проведены в рамках форума.е вызовы, системы психолого-педагогических инструментов,
                  связанные
                  с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет
                  возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forum-list-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
                <router-link to="#" class="forum-list-item-link">
                  Узнать подробнее
                </router-link>
              </div>
            </div>
          </div>
          <button class="forum-list-btn">Загрузить ещё</button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  mounted() {

  },
  filters: {

  },
  data() {
    return {
      options: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      value1: '',
      value2: '',
      value3: '',
    }
  }
}
</script>
<style>

.forums-block {
  padding-top: 75px;
  background: linear-gradient(90deg, #32436d 0%, #02c7fa 100%);
}

.forums-block .events-title-line {
  margin-bottom: 20px;
}

.forums-block .forum-slide-item {
  position: relative;
  z-index: 10;
}

.events-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #FFF;
  margin-bottom: 15px;
}

.events-section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 57.6px;
  color: #FFF;
  margin-top: 50px;
  margin-bottom: 40px;
}

.forums-section {
  height: 700px;
  padding: 80px 0;
  background-repeat: no-repeat;
  background-image: url("/images/forum-section-img.png");
  background-size: 100% auto;
  position: relative;
}

.forums-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #32436DA8;
  z-index: 0;
}

.all-forums {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #FFF;
  border-bottom: 1.5px solid #FFF;
  display: inline-flex;
  width: 125px;
  text-transform: uppercase;
  margin-bottom: 21px;
  position: relative;
  z-index: 10;
}

a {
  text-decoration: none;
}

a:hover {
  color: #FFF;
  text-decoration: none;
}

.forum-slide-item {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.forum-slide-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-details {
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: -0.015em;
  color: #FFF;
  text-shadow: 1px 1px 2px black
}

.forum-slide-details span {
  font-weight: 600;
}

.forum-slide-link {
  display: inline-block;
  background-color: #1090CB;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  padding: 15px 87px;
  border-radius: 13px;
  width: fit-content;
}



.filter-date input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-date input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.filter-date {
  position: relative;
}

.filter-date::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-date.svg");
}

.filter-period {
  position: relative;
}

.filter-period::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-period.svg");
}

.filter-period input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-period input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.filter-place {
  position: relative;
}

.filter-place::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-place.svg");
}

.filter-place input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-place input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.forums-filters {
  display: flex;
  gap: 25px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.forum-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.forum-list-item {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  box-shadow: 0px 3.63px 139.92px 0px #0000000A;
  padding: 25px 0px;
}

.forum-list-item-img {
  height: 293px;
  border-radius: 6px;
}

.forum-list-item-img img {
  border-radius: 6px;
}

.forum-list-item-date {
  font-size: 18.55px;
  font-weight: 400;
  line-height: 31.8px;
  letter-spacing: -0.015em;
  color: #9497A1;
}

.forum-list-item-title {
  font-size: 25.72px;
  font-weight: 800;
  line-height: 38.57px;
  color: #32436D;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.forum-list-item-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #9497A1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.forum-list-item-address {
  font-family: "Open Sans", sans-serif;
  font-size: 17.14px;
  font-weight: 400;
  line-height: 28.29px;
  letter-spacing: -0.015em;
  color: #1090CB;
}

.forum-list-item-link {
  font-size: 13.72px;
  font-weight: 500;
  line-height: 20.57px;
  letter-spacing: -0.015em;
  color: #FFF;
  background-color: #1090CB;
  border-radius: 12px;
  padding: 12px 70px;
  cursor: pointer;
  width: fit-content;

}

.forum-list-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 32.69px;
  color: #1090CB;
  border-radius: 12px;
  border: 1.33px solid #1090CB;
  padding: 12px 70px;
  background: #FFF;
  margin: 0 auto;
  display: block;
  margin-bottom: 120px;
}

.forum-list-item-info {
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}


</style>
